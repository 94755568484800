<!-- 政策库-->
<template>
  <div>
    <div style="width: 100%">
      <div class="zcjsq_banner">
        <div class="input-search-bar">
          <el-input
            class="input-search"
            style="font-size: 16px"
            v-model.trim="inputKeyWord"
            @keyup.enter.native="toSearch"
            placeholder="请输入关键字"
          >
          </el-input>
          <el-button class="input-search-btn" type="primary" @click="toSearch"
            ><span style="font-size: 28px"> 搜索</span>
          </el-button>
        </div>
      </div>
    </div>
    <div
      class="def-container find-list-container"
      style="margin-top: -3.6875rem"
    >
      <el-tabs
        type="card"
        :stretch="true"
        class="tabCard"
        @tab-click="tabClick"
      >
        <el-tab-pane label="按场景分类">
          <div>
            <!-- <div>
              <div>
                <div
                  class="contentBox"
                  v-for="(items, indexs) in mainList"
                  :key="indexs"
                  @click="openDialog(items)"
                >
                  <a>{{ items.scenename }}</a>
                </div>
              </div>
            </div> -->
            <div class="clear"></div>
            <!-- <h2>一件事专区</h2> -->
            <div class="themePart">
              <div
                class="contentBox1"
                v-for="(item, index) in showMainList"
                :key="index"
                @click="toOneThingGuide(item)"
              >
                <div
                  class="imgpart"
                  :style="{ backgroundImage: 'url(' + item.bgimg + ')' }"
                ></div>
                <span class="grcy">{{ item.scenename }}</span>
                <div class="clear"></div>
                <el-divider class="divider" />
                <a :title="item.describe">{{ item.describe }}</a>
                <!-- <a>{{ item.label[0] }}</a>
                <a>{{ item.label[1] }}</a> -->
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <!-- 占位 -->
          <div style="margin-top: 3.4375rem"></div>
        </el-tab-pane>
        <el-tab-pane label="按主题分类">
          <div class="themePart">
            <!-- <p class="p-title">常用主题</p>
            <div>
              <div
                class="contentBox1"
                v-for="(item, index) in themeList"
                :key="index"
              >
                <div
                  class="imgpart"
                  :style="{ backgroundImage: 'url(' + item.bgimg + ')' }"
                ></div>
                <span class="grcy">{{ item.title }}</span>
                <a>{{ item.label[0] }}</a>
                <a>{{ item.label[1] }}</a>
              </div>
            </div>
            <div class="clear"></div> -->
            <!-- <p class="p-title">主题分类</p> -->
            <div class="moreTheme">
              <!-- <div class="showD" ref="showD">
                <button
                  class="moreSp"
                  v-text="showMoreSp == '1' ? '展开全部' : '收起全部'"
                  @click="changeMoreType"
                ></button>
                <div class="labelCont" @click="checkClick($event, 'moreTheme')">
                  <div
                    class="labelTitle"
                    style="float: left"
                    v-for="(item, index) in moreThemeList"
                    :key="index"
                  >
                    <a ref="moreTheme">{{ item.label }}</a>
                  </div>
                </div>
              </div> -->
              <div class="showD" ref="showD" style="height: 120px">
                <div
                  class="labelCont"
                  style="max-width: 88rem"
                  @click="checkClick($event, 'moreTheme')"
                >
                  <div
                    class="labelTitle1"
                    style="float: left"
                    v-for="(item, index) in themeList"
                    :key="index"
                  >
                    <a :title="item.label" ref="moreTheme" class="hiddenview">
                      <span>{{ item.label }}</span>
                    </a>
                  </div>
                  <div class="labelTitle1" style="float: left">
                    <button
                      class="moreSp2"
                      v-text="showMoreSp == '1' ? '展开' : '收起'"
                      @click="changeMoreType"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="min-height: 60px; margin: 20px auto">
              <el-row style="margin-bottom: 20px">全部</el-row>
              <el-row style="width: 100%; margin-bottom: 20px">
                <el-col :span="12">
                  <el-select
                    v-model="value"
                    placeholder="请选择"
                    style="width: 6.875rem"
                  >
                    <el-option> </el-option>
                  </el-select>
                  <el-select
                    v-model="value"
                    placeholder="请选择"
                    style="width: 6.875rem; margin: 0 0.9375rem"
                  >
                    <el-option> </el-option>
                  </el-select>
                  <el-select
                    v-model="value"
                    placeholder="请选择"
                    style="width: 6.875rem; margin: 0 0.9375rem"
                  >
                    <el-option> </el-option>
                  </el-select>
                  <el-checkbox
                    v-model="checked"
                    style="width: 6.25rem; margin: 0 0.9375rem"
                    >可在线申办</el-checkbox
                  >
                </el-col>
                <el-col :span="12" align="right">
                  <el-input
                    placeholder="请输入内容"
                    suffix-icon="el-icon-search"
                    style="width: 30%"
                  >
                  </el-input
                ></el-col>
              </el-row>
              <div></div>
            </div> -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="按部门分类">
          <div class="themePart">
            <div class="moreTheme">
              <div class="showD" ref="showD2" style="height: 120px">
                <div
                  class="labelCont"
                  style="max-width: 88rem"
                  @click="checkClick($event, 'part')"
                >
                  <div
                    class="labelTitle1"
                    style="float: left"
                    v-for="(item, index) in partList"
                    :key="index"
                  >
                    <a :title="item.deptName" ref="part" class="hiddenview">
                      <span>{{ item.deptName }}</span>
                    </a>
                  </div>
                  <div class="labelTitle1" style="float: left">
                    <button
                      class="moreSp2"
                      v-text="showMoreSp2 == '1' ? '展开' : '收起'"
                      @click="changeMoreType2"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="min-height: 60px; margin: 20px auto">
              <el-row style="margin-bottom: 20px">全部</el-row>
              <el-row style="width: 100%; margin-bottom: 20px">
                <el-col :span="12">
                  <el-select
                    v-model="value"
                    placeholder="请选择"
                    style="width: 6.875rem; margin: 0 0.9375rem"
                  >
                    <el-option> </el-option>
                  </el-select>
                  <el-checkbox
                    v-model="checked"
                    style="width: 6.25rem; margin: 0 0.9375rem"
                    >可在线申办</el-checkbox
                  >
                </el-col>
                <el-col :span="12" align="right">
                  <el-input
                    placeholder="请输入内容"
                    suffix-icon="el-icon-search"
                    style="width: 30%"
                  >
                  </el-input
                ></el-col>
              </el-row>
              <div></div>
            </div> -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="按特定对象">
          <div class="themePart">
            <div class="moreTheme">
              <div class="showD" ref="showD3" style="height: 120px">
                <div
                  class="labelCont"
                  style="max-width: 88rem"
                  @click="checkClick($event, 'givenObj')"
                >
                  <div
                    class="labelTitle1"
                    style="float: left"
                    v-for="(item, index) in givenObjList"
                    :key="index"
                  >
                    <a :title="item.label" ref="givenObj">{{ item.label }}</a>
                  </div>
                  <div class="labelTitle1" style="float: left">
                    <button
                      class="moreSp2"
                      v-show="this.givenObjList.length > 13"
                      v-text="showMoreSp3 == '1' ? '展开' : '收起'"
                      @click="changeMoreType3"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="min-height: 60px; margin: 20px auto">
              <el-row style="margin-bottom: 20px">全部</el-row>
              <el-row style="width: 100%; margin-bottom: 20px">
                <el-col :span="12">
                  <el-select
                    v-model="value"
                    placeholder="请选择"
                    style="width: 6.875rem; margin: 0 0.9375rem"
                  >
                    <el-option> </el-option>
                  </el-select>
                  <el-checkbox
                    v-model="checked"
                    style="width: 6.25rem; margin: 0 0.9375rem"
                    >可在线申办</el-checkbox
                  >
                </el-col>
                <el-col :span="12" align="right">
                  <el-input
                    placeholder="请输入内容"
                    suffix-icon="el-icon-search"
                    style="width: 30%"
                  >
                  </el-input
                ></el-col>
              </el-row>
              <div></div>
            </div> -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="按企业类型">
          <div class="themePart">
            <div class="moreTheme">
              <div class="showD" ref="showD4" style="height: 120px">
                <div
                  class="labelCont"
                  style="max-width: 88rem"
                  @click="checkClick($event, 'enterprise')"
                >
                  <div
                    class="labelTitle1"
                    style="float: left"
                    v-for="(item, index) in enterpriseList"
                    :key="index"
                  >
                    <a :title="item.label" ref="enterprise">{{ item.label }}</a>
                  </div>
                  <div class="labelTitle1" style="float: left">
                    <button
                      class="moreSp2"
                      v-if="this.enterpriseList.length > 13"
                      v-text="showMoreSp4 == '1' ? '展开' : '收起'"
                      @click="changeMoreType4"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="min-height: 60px; margin: 20px auto">
              <el-row style="margin-bottom: 20px">全部</el-row>
              <el-row style="width: 100%; margin-bottom: 20px">
                <el-col :span="12">
                  <el-select
                    v-model="value"
                    placeholder="请选择"
                    style="width: 6.875rem; margin: 0 0.9375rem"
                  >
                    <el-option> </el-option>
                  </el-select>
                  <el-checkbox
                    v-model="checked"
                    style="width: 6.25rem; margin: 0 0.9375rem"
                    >可在线申办</el-checkbox
                  >
                </el-col>
                <el-col :span="12" align="right">
                  <el-input
                    placeholder="请输入内容"
                    suffix-icon="el-icon-search"
                    style="width: 30%"
                  >
                  </el-input
                ></el-col>
              </el-row>
              <div></div>
            </div> -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="按事项类型">
          <div class="themePart">
            <div class="moreTheme">
              <div class="showD" ref="showD5" style="height: 120px">
                <div
                  class="labelCont"
                  style="max-width: 88rem"
                  @click="checkClick($event, 'matter')"
                >
                  <div
                    class="labelTitle1"
                    style="float: left"
                    v-for="(item, index) in matterList"
                    :key="index"
                  >
                    <a :title="item.label" ref="matter">{{ item.label }}</a>
                  </div>
                  <div class="labelTitle1" style="float: left">
                    <button
                      class="moreSp2"
                      v-if="this.matterList.length > 13"
                      v-text="showMoreSp5 == '1' ? '展开' : '收起'"
                      @click="changeMoreType5"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="min-height: 60px; margin: 20px auto">
              <el-row style="margin-bottom: 20px">全部</el-row>
              <el-row style="width: 100%; margin-bottom: 20px">
                <el-col :span="12">
                  <el-select
                    v-model="value"
                    placeholder="请选择"
                    style="width: 6.875rem; margin: 0 0.9375rem"
                  >
                    <el-option> </el-option>
                  </el-select>
                  <el-checkbox
                    v-model="checked"
                    style="width: 6.25rem; margin: 0 0.9375rem"
                    >可在线申办</el-checkbox
                  >
                </el-col>
                <el-col :span="12" align="right">
                  <el-input
                    placeholder="请输入内容"
                    suffix-icon="el-icon-search"
                    style="width: 30%"
                  >
                  </el-input
                ></el-col>
              </el-row>
              <div></div>
            </div> -->
          </div>
        </el-tab-pane>
      </el-tabs>
      <div style="padding: 3.75rem 0 7.5rem 0" v-show="this.tabIndex != '0'">
        <el-table
          :data="gridData"
          highlight-current-row
          class="table-policy"
          header-row-class-name="table-policy-header"
          :row-style="tableRowStyle"
        >
          <!-- :cell-style="tableCellStyle" -->
          <el-table-column label="事项名称" min-width="" prop="serviceName">
          </el-table-column>
          <el-table-column label="实施主体" width="" prop="deptName">
          </el-table-column>

          <el-table-column label="操作" width="120" header-align="center">
            <template slot-scope="scope">
              <el-button
                size="small"
                class="tableBtn"
                @click="toserviceGuide(scope.$index, scope.row)"
                >办事指南</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="mt-10"
          background
          hide-on-single-page
          :total="total"
          :page-size="pagesize"
          @current-change="changePageNum"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next,total, jumper"
          style="margin: 20px auto"
        ></el-pagination>
      </div>
    </div>

    <el-dialog title="" :visible.sync="dialogVisible">
      <h3 class="dialogTitle">场景名称</h3>
      <el-row class="dialogCont"> {{ dialogObj.scenename }}</el-row>
      <h3 class="dialogTitle">场景描述</h3>
      <el-row class="dialogCont"> {{ dialogObj.describe }}</el-row>
      <h3 class="dialogTitle">联办事项</h3>
      <el-row class="dialogCont">
        <el-row
          v-for="(item, index) in dialogObj.servicename"
          :key="index"
          @click.native="toserviceGuide2(dialogObj.serviceunid[index])"
          style="cursor: pointer"
        >
          {{ item }}
        </el-row>
      </el-row>
      <h3 class="dialogTitle">受理条件</h3>
      <el-row class="dialogCont">
        <el-col v-for="(item, index) in dialogObj.acceptance" :key="index">
          {{ item }}</el-col
        >
      </el-row>
      <el-row class="dialogTitle">
        <el-col :span="12">总共承诺时限</el-col>
        <el-col :span="12">承诺时间压缩比例</el-col>
      </el-row>
      <el-row class="dialogCont">
        <el-col :span="12">{{ dialogObj.promisetime }}</el-col>
        <el-col :span="12">{{ dialogObj.promisescale }}</el-col>
      </el-row>
      <h3 class="dialogTitle">办理流程</h3>
      <el-row class="dialogCont">
        <el-col v-for="(item, index) in dialogObj.handlprocess" :key="index">
          {{ item }}</el-col
        >
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { LOGIN_URL, LOGIN_URL_BACK } from "@/store/mutation-types";
export default {
  components: {},
  data() {
    return {
      inputKeyWord: "",
      mainList: [
        {
          bgimg: require("@/assets/images/grsysy.png"),
        },
        {
          bgimg: require("@/assets/images/grhjbl.png"),
        },
        {
          bgimg: require("@/assets/images/grzfbz.png"),
        },
        {
          bgimg: require("@/assets/images/grshbz.png"),
        },
        {
          bgimg: require("@/assets/images/grjtcx.png"),
        },
        {
          bgimg: require("@/assets/images/grcjrj.png"),
        },
        {
          bgimg: require("@/assets/images/grjycy.png"),
        },
        {
          bgimg: require("@/assets/images/grzjbl.png"),
        },
      ],
      showMainList: [],
      dialogVisible: false,
      gridData: [],
      themeList: [], //主题分类
      moreThemeList: [], //更多主题
      partList: [], //部门列表
      morepartList: [], //部门列表对比数组
      givenObjList: [], //特定对象
      enterpriseList: [], //企业类型
      matterList: [], //事项类型
      showMoreSp: "1", //展开收起按钮控制
      showMoreSp2: "1",
      showMoreSp3: "1",
      showMoreSp4: "1",
      showMoreSp5: "1",
      tableRowStyle: {
        color: "#bd1a2d",
        fontSize: "18px",
      },
      geturl: "/dev-api/system/dict/getDictListByType?dictType=", //获取字典地址
      pagesize: 15,
      total: null,
      pageNum: 1,
      tabIndex: "0", //当前tabs页索引
      chooseValue: "", //分类选中后的当前key值或deptcode
      checked: [], //多选框是否选中
      dialogObj: {}, //弹窗数据
    };
  },
  computed: {},
  created() {},
  mounted() {
    // this.getZtList("service_enterprise_zt");
    // this.getTddxList("service_enterprise_tddx");
    // this.getQylxList("enterprise_size");
    // this.getSxlxList("service_type");
    // this.getCjList();
    // this.getDeptList();
    // this.getList();
    this.init();
  },
  watch: {},
  methods: {
    // 初始化查询方法
    init() {
      this.getZtList("service_enterprise_zt");
      this.getTddxList("service_enterprise_tddx");
      this.getQylxList("enterprise_size");
      this.getSxlxList("service_type");
      this.getCjList();
      this.getDeptList();
      this.getList(15, 1);
      if (this.$route.query.item) {
        this.openDialog(this.$route.query.item);
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    openDialog(item) {
      // 使用中间对象防止split分割时改变数据,通过JSON实现深复制
      let obj = JSON.parse(JSON.stringify(item));
      this.dialogObj = obj;
      this.dialogObj.acceptance = obj.acceptance.split("\n");
      this.dialogObj.handlprocess = obj.handlprocess.split("\n");
      this.dialogObj.servicename = obj.servicename.split(",");
      this.dialogObj.serviceunid = obj.serviceunid.split(",");
      this.dialogVisible = true;
    },
    toserviceGuide(index, row) {
      // if (this.$store.getters.getUserInfo) {
      // sessionStorage.setItem(
      //   "titleTypeBack",
      //   sessionStorage.getItem("titleType")
      // );
      //sessionStorage.setItem("titleType", 9);
      sessionStorage.setItem("matterRow", JSON.stringify(row));
      sessionStorage.setItem("matterChooseType", "1");
      this.$router.push({ name: "serviceGuide" });
      // } else {
      //   //未登录
      //   this.toLogin("policyMatchReport");
      // }
    },
    toserviceGuide2(unid) {
      // if (this.$store.getters.getUserInfo) {
      // sessionStorage.setItem(
      //   "titleTypeBack",
      //   sessionStorage.getItem("titleType")
      // );
      // //sessionStorage.setItem("titleType", 9);
      sessionStorage.setItem("matterunid", JSON.stringify(unid));
      sessionStorage.setItem("matterChooseType", "2");
      this.$router.push({ name: "serviceGuide" });
      // } else {
      //   //未登录
      //   this.toLogin("policyMatchReport");
      // }
    },
    toOneThingGuide(item) {
      sessionStorage.setItem("onethingGuideItem", JSON.stringify(item));
      this.$router.push({ name: "oneThingGuide" });
    },
    changeMoreType() {
      let showD = this.$refs.showD;
      this.showMoreSp = this.showMoreSp == "1" ? "2" : "1";
      switch (this.showMoreSp) {
        case "1":
          this.themeList = this.moreThemeList.slice(0, 13);
          break;
        case "2":
          this.themeList = this.moreThemeList;
          break;
        default:
          break;
      }
      showD.style.height = showD.clientHeight == 120 ? "300px" : "120px";
    },
    changeMoreType2() {
      let showD = this.$refs.showD2;
      this.showMoreSp2 = this.showMoreSp2 == "1" ? "2" : "1";
      switch (this.showMoreSp2) {
        case "1":
          this.partList = this.morepartList.slice(0, 13);
          break;
        case "2":
          this.partList = this.morepartList;
          break;
        default:
          break;
      }
      showD.style.height = showD.clientHeight == 120 ? "21.875rem" : "7.5rem";
    },
    // 按特定对象分类收起展开
    changeMoreType3() {
      let showD = this.$refs.showD3;
      this.showMoreSp3 = this.showMoreSp3 == "1" ? "2" : "1";
      switch (this.showMoreSp3) {
        case "1":
          this.givenObjList = this.givenObjList.slice(0, 13);
          break;
        case "2":
          this.givenObjList = this.givenObjList;
          break;
        default:
          break;
      }
      showD.style.height = showD.clientHeight == 120 ? "12.5rem" : "7.5rem";
    },
    //按企业类型分类收起展开
    changeMoreType4() {
      let showD = this.$refs.showD4;
      this.showMoreSp4 = this.showMoreSp4 == "1" ? "2" : "1";
      switch (this.showMoreSp4) {
        case "1":
          this.enterpriseList = this.enterpriseList.slice(0, 13);
          break;
        case "2":
          this.enterpriseList = this.enterpriseList;
          break;
        default:
          break;
      }
      showD.style.height = showD.clientHeight == 120 ? "37.5rem" : "7.5rem";
    },
    //按事项类型分类收起展开
    changeMoreType5() {
      let showD = this.$refs.showD5;
      this.showMoreSp5 = this.showMoreSp5 == "1" ? "2" : "1";
      switch (this.showMoreSp5) {
        case "1":
          this.matterList = this.matterList.slice(0, 13);
          break;
        case "2":
          this.matterList = this.matterList;
          break;
        default:
          break;
      }
      showD.style.height = showD.clientHeight == 120 ? "37.5rem" : "7.5rem";
    },
    // tableCellStyle({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 1) {
    //     // 指定列号
    //     return "color:#adadad";
    //   } else {
    //     return "";
    //   }
    // },
    // 按主题分类
    getZtList(id) {
      let url = this.geturl + id;
      this.$httpApi.get(url).then((r) => {
        this.moreThemeList = r.data;
        this.themeList = this.moreThemeList.slice(0, 13);
      });
    },
    // 按特定对象
    getTddxList(id) {
      let url = this.geturl + id;
      this.$httpApi.get(url).then((r) => {
        this.givenObjList = r.data;
      });
    },
    // 按企业类型
    getQylxList(id) {
      let url = this.geturl + id;
      this.$httpApi.get(url).then((r) => {
        this.enterpriseList = r.data;
      });
    },
    // 按事项类型
    getSxlxList(id) {
      let url = this.geturl + id;
      this.$httpApi.get(url).then((r) => {
        this.matterList = r.data;
      });
    },
    // 按场景分类
    getCjList() {
      let that = this;
      let url = "/onething/scene/list";
      this.$httpApi.get(url).then((r) => {
        let list = r.rows;
        console.log(JSON.stringify(list));
        // that.themeList.forEach((item,index)=>{
        //   item.title=list[index].scenename
        //   item.label=list[index].describe
        // })
        list.forEach((element, index) => {
          Object.keys(element).forEach((item) => {
            that.mainList[index][item] = element[item];
          });
        });
        if (list.length < 8) {
          that.showMainList = that.mainList.slice(0, list.length);
        }
      });
    },
    // 按部门分类
    getDeptList(id) {
      let url = "/preService/service/getServiceDeptInfoList";
      this.$httpApi.get(url).then((r) => {
        this.morepartList = r.data;
        this.partList = this.morepartList.slice(0, 13);
      });
    },
    // 获取事项
    getList(pagesize, pagenum, chooseType, value) {
      let url =
        "/preService/service/postList?pageSize=" +
        pagesize +
        "&pageNum=" +
        pagenum;
      let params = {};
      switch (chooseType) {
        case "givenObj": //特定对象
          params["enterpriseJyhd"] = value;
          break;
        case "part": //部门分类
          params["deptCode"] = value;
          break;
        case "enterprise": //企业类型
          params["enterpriseTddx"] = value;
          break;
        case "matter": //事项类型
          params["serviceType"] = value;
          break;
        case "moreTheme": //主题分类
          params["enterpriseZt"] = value;
          break;
        default:
          break;
      }
      this.$httpApi
        .post(url, params)
        .then((r) => {
          this.gridData = r.rows;
          this.total = r.total;
        })
        .catch((e) => {});
    },
    // 各类分类选中事件
    checkClick(e, choose) {
      let givenObj = this.$refs.givenObj;
      let part = this.$refs.part;
      let enterprise = this.$refs.enterprise;
      let matter = this.$refs.matter;
      let moreTheme = this.$refs.moreTheme;
      let theme = this.$refs.theme;
      if (e.target.innerText.length > 20) {
        return;
      }
      switch (choose) {
        case "givenObj":
          for (let i = 0; i < givenObj.length; i++) {
            givenObj[i].style.color = "#333";
            givenObj[i].style.backgroundColor = "#e9e9e9";
            if (e.target.innerHTML == this.givenObjList[i].label) {
              givenObj[i].style.color = "#fff";
              givenObj[i].style.backgroundColor = "#bd1a2d";
              this.chooseValue = this.givenObjList[i].key;
              this.getList(15, 1, "givenObj", this.chooseValue);
            }
          }
          break;
        case "part":
          for (let i = 0; i < part.length; i++) {
            part[i].style.color = "#333";
            part[i].style.backgroundColor = "#e9e9e9";
            if (e.target.innerHTML == this.partList[i].deptName) {
              part[i].style.color = "#fff";
              part[i].style.backgroundColor = "#bd1a2d";
              this.chooseValue = this.partList[i].deptCode;
              this.getList(15, 1, "part", this.chooseValue);
            }
          }
          break;
        case "enterprise":
          for (let i = 0; i < enterprise.length; i++) {
            enterprise[i].style.color = "#333";
            enterprise[i].style.backgroundColor = "#e9e9e9";
            if (e.target.innerHTML == this.enterpriseList[i].label) {
              enterprise[i].style.color = "#fff";
              enterprise[i].style.backgroundColor = "#bd1a2d";
              this.chooseValue = this.enterpriseList[i].key;
              this.getList(15, 1, "enterprise", this.chooseValue);
            }
          }
          break;
        case "matter":
          for (let i = 0; i < matter.length; i++) {
            matter[i].style.color = "#333";
            matter[i].style.backgroundColor = "#e9e9e9";
            if (e.target.innerHTML == this.matterList[i].label) {
              matter[i].style.color = "#fff";
              matter[i].style.backgroundColor = "#bd1a2d";
              this.chooseValue = this.matterList[i].key;
              this.getList(15, 1, "matter", this.chooseValue);
            }
          }
          break;
        case "moreTheme":
          for (let i = 0; i < moreTheme.length; i++) {
            moreTheme[i].style.color = "#333";
            moreTheme[i].style.backgroundColor = "#fff";
            if (e.target.innerHTML == this.moreThemeList[i].label) {
              moreTheme[i].style.color = "#fff";
              moreTheme[i].style.backgroundColor = "#bd1a2d";
              this.chooseValue = this.moreThemeList[i].key;
              this.getList(15, 1, "moreTheme", this.chooseValue);
            }
          }
          break;
        // case "theme":
        //   for (let i = 0; i < theme.length; i++) {
        //     theme[i].style.color = "#333";
        //     theme[i].style.backgroundColor = "#fff";
        //     if (e.target.innerHTML == this.themeList[i].label) {
        //       theme[i].style.color = "#fff";
        //       theme[i].style.backgroundColor = "#bd1a2d";
        //       this.chooseValue = this.themeList[i].key;
        //       this.getList(5, 1, "moreTheme", this.chooseValue);
        //     }
        //   }
        //   break;
        default:
          break;
      }
    },
    // 卡片切换事件
    tabClick(tab, event) {
      this.tabIndex = tab.index;
    },
    // 分页切换
    changePageNum(index) {
      this.pageNum = index;
      let pageSize = this.pagesize;
      let pageNum = this.pageNum;
      let chooseType = "";
      switch (this.tabIndex) {
        case "1":
          chooseType = "moreTheme";
          break;
        case "2":
          chooseType = "part";
          break;
        case "3":
          chooseType = "givenObj";
          break;
        case "4":
          chooseType = "enterprise";
          break;
        case "5":
          chooseType = "matter";
          break;
        default:
          break;
      }
      this.getList(pageSize, pageNum, chooseType, this.chooseValue);
    },
    // 登录
    toLogin(backurl) {
      this.$router.push({ path: "/login" });
    },
    // 搜索
    toSearch() {
      sessionStorage.setItem("menuIndex", "3");
      //sessionStorage.setItem("titleType", 1);
      this.$router.push({
        name: "searchList",
        query: { keyword: this.inputKeyWord },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
h2 {
  color: #333333;
  margin: 3.9375rem 0 1.875rem 0;
}
::v-deep .el-loading-spinner i {
  font-size: 3.75rem;
  color: #3fffff;
}
::v-deep .el-loading-text {
  font-size: 1.25rem;
  color: #3fffff;
}
.zcjsq_banner {
  width: 100%;
  height: 12.5rem;
  background: url("~@/assets/images/底图.png") no-repeat 100% / cover;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #fff;
    font-weight: 600;
    font-size: 1.375rem;
    margin-left: -24%;
  }
  .input-search-bar {
    height: 55px;
    width: 50%;
    margin: auto;
    margin-top: -20px;
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    margin-top: 40px;
    ::v-deep .el-input__inner {
      border: none !important;
      height: 55px;
      line-height: 55px;
    }
    /* 输入框头部 */
    .input-search-pre {
      width: 120px !important;
      color: #404040;
      font-size: 16px;
      background: $color-fff;
      .el-input__inner {
        text-align: center !important;
        border: none !important;
        border-radius: 0 !important;
        color: #333;
        background-color: #ffffff !important;
      }
    }

    /* 输入框内容 */
    .input-search {
      flex: 1;
      .el-input__inner {
        border: none !important;
        border-radius: 0 !important;
        background-color: #ffffff !important;
        font-size: 18px;
      }
      input::-webkit-input-placeholder {
        color: #999999;
      }
    }
    /* 输入框按钮 */
    .input-search-btn {
      background: #e60000;
      height: 100%;
      width: 100px;
      border-radius: 0 !important;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      border: 2px solid #b6a99c !important;
      text-align: center;
      font-size: $font-size18;
      color: $color-fff;
      cursor: pointer;
      .el-button {
        font-weight: bold !important;
      }
    }
  }
}
.find-list-container {
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }

  .el-tabs__item.is-active {
    color: #ef4747;
  }
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;

    .banner-other {
      margin-left: 0.9375rem;
    }
  }

  .section-header {
    border-bottom: 0.25rem solid #000;
    margin-top: 1.875rem;
    display: flex;
    justify-content: space-between;
  }

  .section-title {
    font-size: 1.5rem;
    color: rgb(13, 28, 40);
    font-weight: 600;
    height: 2.875rem;
    line-height: 2.875rem;
  }

  .rank-section {
    padding-top: 0.625rem;
    font-size: 0.8125rem;
  }

  .find-tabs {
    margin-top: 1.25rem;
  }
}
::v-deep .el-input-group__append {
  background-color: #e0240a;
  color: #fff;
}
::v-deep .el-tabs__item {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-size: 1.375rem;
  width: 11.3125rem;
  height: 3.6875rem;
  line-height: 3.6875rem;
}
::v-deep .el-tabs__item.is-active {
  color: #cd0600;
  font-size: 1.375rem;
}
::v-deep .tabCard {
  .el-tabs__nav.is-stretch {
    background: rgba(150, 10, 9, 0.8);
  }
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #fff;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-radius: 0;
  border: none;
}
::v-deep [class*=" el-icon-"],
[class^="el-icon-"] {
  color: #bd1a2d;
  font-size: 28px;
}
.find-list-container {
  .el-input {
    input {
      background-color: #ffffff;
      border-radius: 0rem !important;
    }
  }
  .el-input-group__append {
    button {
      background-color: rgb(75, 145, 233);
    }
  }

  .el-row {
    width: 22.5rem;
    display: flex;

    .el-button {
      border: 0rem;
      border-radius: 0rem;
      width: 5.625rem;

      .el-icon-bottom {
        margin-left: 0.9375rem;
      }

      &:hover {
        background-color: #2d8cf0;
      }
    }
  }
}
::v-deep .searchItem-amount {
  border-top: none !important;

  .filterItem-label {
    opacity: 0;
  }
  .filterItem-content {
    padding-left: 4.5625rem;

    .content-item--active {
      color: $col-theme !important;
      background: none !important;
    }
  }

  .searchItem-amount-custom {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #999999;

    .form-input {
      width: 5rem;
      margin-right: 0.3125rem;
    }
  }
}
.search-params-clean {
  margin-right: 0.3125rem;
  color: $col-red;
}

.find-list-items-container {
  .item {
    margin-bottom: 0.625rem;
    box-sizing: border-box;
  }
}
::v-deep .table-policy {
  .table-policy-header {
    th {
      color: #333;
      background-color: #ededed;
      border: none;
      margin-bottom: 0.625rem;
      font-size: 18px;
    }
  }
  .policy-item {
    .policyItem-hylb,
    .policyItem-qyzz,
    .policyItem-ryxxyq {
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
    .policyItem-zzxe {
      color: $col-red;
    }
    .policyItem-gssz {
      display: -webkit-flex;
      display: flex;
      align-items: center;

      p {
        flex: 1;
        padding-right: 1.25rem;
      }
      .tj-icon {
        font-size: 1.25rem;
        color: #999999;

        &:hover {
          color: #666666;
        }
      }
    }

    &:hover {
      font-weight: normal;
      cursor: pointer;
    }
  }
}
.pagination {
  margin: 1.875rem auto;
  text-align: center;
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: $col-theme;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: $col-theme;
  }
  .filterItem-right span {
    color: $col-theme;
  }
  .el-checkbox-button__inner:hover {
    color: $col-theme;
  }
  .is-checked .el-checkbox-button__inner:hover {
    color: #fff;
  }
}
.contentBox {
  width: 17.8125rem;
  height: 5.625rem;
  float: left;
  margin: 0.625rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  a {
    display: table-cell;
    width: 17.8125rem;
    height: 5rem;
    vertical-align: middle;
    text-align: center;
    -webkit-box-sizing: border-box;
    -webkit-box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.1);
    font-size: 18px;
    box-sizing: border-box;
    padding: 0.5625rem 1.875rem;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-decoration: none;
    color: #000;
  }
}
.contentBox :hover {
  color: #ffffff !important;
  background-color: #bd1a2d;
  box-shadow: unset;
}
.clear {
  clear: both;
}
.themePart {
  .p-title {
    font-size: 1.3125rem;
    color: #333333;
    font-weight: 700;
    margin: 3.9375rem 0 1.875rem 0;
  }
  .contentBox1 {
    float: left;
    width: 21.2rem;
    height: 200px;
    margin: 0.625rem;
    -webkit-box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #333333;
    font-size: 1.125rem;
    cursor: pointer;
    .imgpart {
      display: inline-block;
      width: 4.625rem;
      height: 4.125rem;
      margin: 1.3125rem;
      vertical-align: middle;
      float: left;
      background-position: -3.1875rem -3.1875rem;
      background-repeat: no-repeat;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .grcy {
      display: block;
      float: left;
      width: 10rem;
      margin-top: 1.875rem;
      font-weight: 600;
      font-size: 20px;
    }
    a {
      display: inline-block;
      font-size: 18px;
      color: #707070;
      margin-top: 12px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 10px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      height: 3.625rem;
      overflow: hidden;
    }
  }
  .moreTheme {
    margin: 0 -0.9375rem;
    position: relative;
    .showD {
      overflow: hidden;
      height: 4.5rem;
      padding-top: 0.625rem;
    }
    .moreSp {
      width: 7.0625rem;
      height: 3.75rem;
      margin: 0 0.3125rem 0.625rem 0.3125rem;
      -webkit-box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.1);
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color: #333333;
      text-align: center;
      line-height: 3.75rem;
      font-size: 1rem;
      position: absolute;
      top: 0.625rem;
      right: 50px;
      background-color: #fff;
    }
    .moreSp2 {
      display: block;
      width: 9.6875rem;
      height: 2.5rem;
      margin: 0 0.5625rem 1.25rem 0.625rem;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color: #c94252;
      text-align: center;
      line-height: 2.5rem;
      font-size: 1rem;
      top: 4.375rem;
      right: 7px;
      z-index: 3;
      background-color: #f9dfe2;
      cursor: pointer;
    }
    .labelCont {
      zoom: 1;
      max-width: 1080px;
      margin: 0 1.25rem;
      .labelTitle :hover,
      .labelTitle1 :hover {
        color: #ffffff !important;
        background-color: #bd1a2d !important;
        box-shadow: unset;
      }
      .labelTitle1 {
        a {
          box-shadow: unset;
          width: 9.6875rem;
          background-color: #e9e9e9;
          margin: 0 0.5625rem 1.25rem 0.625rem;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          color: #333333;
          text-align: center;
          height: 2.5rem;
          font-size: 18px;
          cursor: pointer;
          span {
            width: 155px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            height: 40px;
            line-height: 40px;
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        width: 7.5rem;
        height: 3.75rem;
        margin: 0 0.3125rem 0.9375rem 0.3125rem;
        box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        color: #000;
      }
    }
  }
  .dataList {
    margin-top: 3.4375rem;
  }
}
.contentBox1:hover {
  background-color: #cd0600;
  color: #ffffff !important;
}
.contentBox1:hover a {
  color: #ffffff !important;
}
.contentBox1:hover .imgpart {
  background-position: -51px -203px;
}
.tableBtn {
  border: 1px solid #bd1a2d;
  color: #bd1a2d;
  font-size: 16px;
}
.dialogCont {
  min-height: 40px;
  line-height: 40px;
  border-bottom: 1px dashed #333;
  color: #bd1a2d;
}
.dialogTitle {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  margin-top: 16px;
}
.hiddenview {
  overflow: hidden;
}
.divider {
  background-color: #ccc;
}
::v-deep .el-divider--horizontal {
  margin: 0 0 8px 0;
}
</style>
